.Service-card-cover{
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    transition: top 0.5s ease-out;
}

#cover-image{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Service-card-cover::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.45;
}

#cover-text-holder{
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    color: #fff;
    padding: 17px;
}

#cover-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
